import { TIME, DASH_DATE, SLASH_DATE } from '@/libs/formatDate'

export const labelClaims = value => {
  let result
  switch (value) {
    case 0:
      result = 'Diajukan'
      break
    case 1:
      result = 'Disetujui'
      break
    case 2:
      result = 'Ditolak'
      break
    default:
      break
  }
  return result
}

export const claimStatusClass = value => {
  let result
  switch (value) {
    case 0:
      result = 'wrapper-status-claim'
      break
    case 1:
      result = 'wrapper-status-received'
      break
    case 2:
      result = 'wrapper-status-rejected'
      break
    default:
      break
  }
  return result
}

export const anotationClaimsClass = value => {
  let result
  switch (value) {
    case 0:
      result = 'text-[#FBA63C]'
      break
    case 1:
      result = 'text-[#34A770]'
      break
    case 2:
      result = 'text-[#E31A1A]'
      break
    default:
      break
  }
  return result
}

export const showLabelStatusClaim = value => {
  let result
  switch (value.claim_status) {
    case 0:
      result = 'diajukan'
      break
    case 1:
      result = 'diterima'
      break
    case 2:
      result = 'ditolak'
      break
    default:
      break
  }
  return `Sudah ${result} pada ${DASH_DATE(value.updated_at)} (${TIME(value.updated_at)}).`
}

export const defineLabelClaims = value => {
  let result
  if (value.claim_type === 'paksa_diterima') {
    result = 'Diterima'
  } else if (value.claim_type === 'tidak_diterima') {
    result = 'Delivered Tidak Diterima'
  } else if (value.claim_type === 'rusak') {
    result = 'Delivered Rusak'
  } else if (value.claim_type === 'paksa_rts') {
    result = 'Retur Diterima'
  } else if (value.claim_type === 'rts_tidak_diterima') {
    result = 'RTS Tidak Diterima'
  } else if (value.claim_type === 'rts_rusak') {
    result = 'RTS Rusak'
  } else if (value.claim_type === 'retur') {
    result = 'Retur'
  } else if (value.claim_type === 'hilang') {
    result = 'Hilang'
  } else {
    result = '-'
  }
  return result
}

export const getMessageClaims = value => {
  let claimType
  if (value.claim_type === 'paksa_diterima' || value.claim_type === 'paksa_rts') {
    claimType = 'Laporan'
  } else {
    claimType = 'Claim'
  }

  return `${claimType} Paket ${defineLabelClaims(value)} ${labelClaims(value.claim_status) === 'Diajukan' ? 'Sedang' : 'Telah'} ${labelClaims(value.claim_status)} (${SLASH_DATE(value.updated_at)})`
}

export const getInfoClaimsList = value => {
  let claimType
  if (value.claim_type === 'paksa_diterima' || value.claim_type === 'paksa_rts') {
    claimType = 'Laporan'
  } else {
    claimType = 'Claim'
  }

  return `${claimType} ${defineLabelClaims(value)} ${labelClaims(value.claim_status)}`
}
