<template>
  <b-row
    v-if="banner"
    class="bg-[#FFF8E1] p-1 mx-1 mb-1 rounded-lg justify-content-between"
  >
    <b-col
      md="9"
      class="d-flex"
    >
      <img
        class="align-self-start mr-[5px]"
        src="https://storage.googleapis.com/komerce/assets/svg/warning-Info-circle.svg"
        alt="info"
      >
      <span class="mt-[4px]">
        <b>{{ title }}</b>
        <div
          v-for="item of message"
          :key="item"
        >
          <li>{{ item }}</li>
        </div>
      </span>
    </b-col>
    <b-col
      md="3"
      style="text-align: -webkit-right;"
    >
      <img
        type="button"
        class="w-5 mb-1"
        src="https://storage.googleapis.com/komerce/assets/svg/close-circle.svg"
        alt=""
        @click="banner = false"
      >
      <div>
        <button
          class="text-[#FBA63C] p-[7px] rounded-lg outline outline-1 outline-[#FBA63C] focus:outline-[#FBA63C]"
          @click="openTo('https://bantuan.komerce.id/id/article/panduan-claim-ganti-rugi-di-komship-1z2sd1/')"
        >
          Ke Pusat Bantuan
        </button>
      </div>
    </b-col>
  </b-row>
</template>
<script>

export default {
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    message: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      banner: true,
    }
  },
  methods: {
    openTo(value) {
      window.open(value)
    },
  },
}
</script>
