<template>
  <b-modal
    :id="`bundling-${String(index)}-${$route.name === 'data-order' ? String(data.detail_id) : String(data.product_id)}`"
    hide-footer
    size="lg"
  >
    <div class="h-min p-[16px]">
      <div class="border rounded-t w-[100%]">
        <div class="bg-[#FFECE9] py-[8px] px-[24px] rounded-t text-black font-[500]">
          Produk
        </div>
        <b-table
          :items="data.bundle_component"
          :fields="fieldsBundling"
        >
          <template #cell(no)="dataBundle">
            {{ dataBundle.index + 1 }}
          </template>
          <template #cell(product_name)="dataBundle">
            <div class="flex gap-[16px] items-start">
              <img
                :src="getImage(dataBundle.item)"
                alt="product"
                width="52"
                height="52"
              >
              <div class="space-y-[4px]">
                <div class="text-[16px]">
                  {{ dataBundle.item.product_name }}
                </div>
                <div
                  v-if="$route.name === 'add-order'"
                  class="text-[14px]"
                >
                  SKU: {{ dataBundle.item.sku }}
                </div>
                <div
                  v-else
                  class="text-[14px]"
                >
                  SKU: {{ dataBundle.item.product_sku }}
                </div>
              </div>
            </div>
          </template>
          <template #cell(variant_name)="dataBundle">
            <div
              v-if="$route.name === 'add-order'"
              class="text-center"
            >
              {{ dataBundle.item.dataTableComp.variant_name }}
            </div>
            <div
              v-else
              class="text-center"
            >
              {{ dataBundle.item.product_variant_name }}
            </div>
          </template>
          <template #cell(quantity)="dataBundle">
            <div
              v-if="$route.name === 'add-order'"
              class="text-center"
            >
              {{ dataBundle.item.dataTableComp.quantity }}
            </div>
            <div
              v-else
              class="text-center"
            >
              {{ dataBundle.item.qty }}
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
  props: {
    index: {
      type: Number,
      default: 0,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fieldsBundling: [
        {
          key: 'no',
          label: 'No',
          thClass: 'font-[500] text-[#222] !text-[14px] !capitalize bg-white',
          tdClass: 'text-[#828282]',
        },
        {
          key: 'product_name',
          label: 'Nama Produk',
          thClass: 'font-[500] text-[#222] !text-[14px] !capitalize bg-white',
          tdClass: 'text-[#828282]',
        },
        {
          key: 'variant_name',
          label: 'Variasi',
          thClass: 'font-[500] text-[#222] !text-[14px] !capitalize text-center bg-white',
          tdClass: 'text-[#828282]',
        },
        {
          key: 'quantity',
          label: 'Jumlah',
          thClass: 'font-[500] text-[#222] !text-[14px] !capitalize text-center bg-white',
          tdClass: 'text-[#828282]',
        },
      ],
    }
  },
  methods: {
    getImage(value) {
      let result
      if (this.$route.name === 'add-order') {
        result = value.product_image[0] ? value.product_image[0].images_path : null
      } else if (this.$route.name === 'detail-order') {
        result = value.product_image
      } else if (this.$route.name !== 'add-order') {
        result = value.product_images[0] ? value.product_images[0].images_path : null
      } else if (result === null) {
        result = 'https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg'
      }
      return result
    },
  },
}
</script>
