<template>
  <BModal
    ref="modal-success"
    centered
    hide-header
    hide-footer
  >
    <b-row class="justify-content-center my-2">
      <b-img
        src="https://storage.googleapis.com/komerce/core/icon-popup-success.png"
        width="100"
      />
    </b-row>

    <b-row class="justify-content-center mb-50">
      <span class="text-[#333333] text-[24px]">
        <strong>
          Laporan Kami Terima
        </strong>
      </span>
    </b-row>

    <b-row class="mb-2 text-center justify-content-center mx-1">
      <span class="text-[#626262]">
        Pengajuan perubahan status order menjadi “{{ status === 'retur' ? 'Retur Diterima' : 'Diterima' }}” berhasil
        terkirim. Tim kami akan {{ status === 'retur' ? 'memverifikasinya' : 'memverifikasi dalam 5 hari' }}. Kamu dapat
        memantau perkembangannya di detail order resi yang diajukan.
      </span>
    </b-row>

    <b-row class="mb-2 justify-content-center mx-1">
      <b-button
        variant="primary"
        block
        :to="{ name: $route.meta.routeDetail, params: { order_id: orderId } }"
      >
        Lihat Detail Order
      </b-button>
    </b-row>
  </BModal>
</template>

<script>
export default {
  props: {
    orderId: {
      type: Number,
      default: 0,
      required: true,
    },
    status: {
      type: String,
      default: '',
      required: true,
    },
  },
  methods: {
    show() {
      this.$refs['modal-success'].show()
    },
  },
}
</script>
