<template>
  <div>
    <BModal
      ref="modal-force"
      centered
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      @hidden="closeModalForce"
    >

      <BRow class="my-3">

        <BCol
          cols="12"
          class="mb-1 d-flex justify-content-center"
        >
          <img
            src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
            width="100"
          >
        </BCol>

        <BCol
          cols="12"
          class="mb-1 text-center"
        >
          <span class="text-[#333333] text-[24px]">
            <strong>
              Lapor! Paket Sudah Diterima
            </strong>
          </span>
        </BCol>

        <BCol
          cols="12"
          class="text-center mb-1"
        >
          <span class="text-[#626262]">
            Laporkan bukti pendukung bahwa paket tersebut memang telah diterima pihak {{ status === 'delivered' ? 'buyer' : 'seller' }}. Orderan ini akan berubah statusnya menjadi {{ status === 'delivered' ? '"Diterima"' : '"Retur Diterima" (RTS)' }} setelah diperiksa dan disetujui oleh Admin
          </span>
        </BCol>

        <BCol cols="12">
          <div class="form-group mb-2">
            <label
              for="video"
              class="text-[#333333]"
            >Upload Video</label>
            <div class="input-group">
              <input
                id="video"
                v-model="inputModel"
                type="text"
                class="form-control input-proof"
                placeholder="Masukkan link video"
                @focus="onInputFocus"
                @blur="validationUrl"
              >
              <div class="input-group-append">
                <span
                  class="input-group-text input-proof"
                >Link</span>
              </div>
            </div>
            <small
              v-if="urlError === 'invalid'"
              class="text-primary"
            >*URL tidak valid</small>
          </div>

          <div class="mb-2">
            <div>
              <small class="text-[#333333]">Upload Foto<small class="text-primary">*</small></small>
            </div>
            <label
              for="files-proof"
              class="cursor-pointer w-full"
              :style="
                fileList.length < 3 ? 'cursor: pointer!important;' : 'cursor: not-allowed!important;'
              "
            >
              <div class="input-group justify-content-between w-full">
                <div class="wrapper-choose-file pl-1 pt-50">
                  <span class="text-[14px] text-muted">
                    Pilih File
                  </span>
                </div>
                <div class="input-group-append">
                  <span
                    class="input-group-text input-proof"
                  >File</span>
                </div>
              </div>
            </label>
            <small
              v-if="fileSizeExceeded"
              class="text-primary"
            >*Ukuran file maksimal 2mb</small>
            <input
              id="files-proof"
              type="file"
              multiple
              hidden
              accept="image/x-png,image/jpg,image/jpeg"
              :disabled="fileList.length < 3 ? false : true"
              @input="handleFileInput($event)"
            >
          </div>

          <!-- Preview File -->
          <div class="d-flex flex-wrap">
            <div
              v-for="(file, index) in fileList"
              :key="index"
              class="wrapper-preview-file mr-2 mb-2"
            >
              <b-img
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/close-circle.svg"
                class="close-img-preview"
                @click="removeFile(index)"
              />
              <b-img
                :src="file.url"
                class="img-preview"
                @click="openPreview(file)"
              />
              <b-spinner
                v-if="file.spinner"
                class="custom-spinner-preview"
                variant="light"
              />
              <div>
                <span class="text-[#333333]">
                  ...{{ nameFiles[index].slice(-9) }}
                </span>
              </div>
            </div>
          </div>

          <div class="d-flex mt-2">
            <b-button
              variant="outline-primary"
              class="mr-1 mt-0 btn-submit"
              block
              @click="hideModalForceDelivered"
            >
              Batal
            </b-button>
            <b-button
              variant="primary"
              :class="validationForce() ? 'btn-submit-disabled mt-0' : 'btn-submit mt-0'"
              block
              :disabled="validationForce()"
              @click="submit"
            >
              Laporkan
              <b-spinner
                v-if="loadingClaims"
                small
                class="ml-50"
              />
            </b-button>
          </div>
        </BCol>
      </BRow>

    </BModal>

    <!-- Modal Preview Img -->
    <BModal
      ref="modal-preview-img"
      centered
      hide-header
      hide-footer
      size="xl"
    >
      <BRow class="justify-content-center my-4">
        <BImg :src="prevImg" />
      </BRow>
    </BModal>

    <ModalSuccess
      ref="modal-success"
      :order-id="orderId"
      :status="status"
    />
  </div>
</template>

<script>
import ToastificationContent from 'vue-toastification'
import ModalSuccess from './ModalSuccess.vue'

const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/

export default {
  components: { ModalSuccess },
  props: {
    orderId: {
      type: Number,
      default: 0,
      required: true,
    },
    status: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      shortUrl: null,
      fullUrl: null,
      urlError: '',
      fileList: [],
      nameFiles: [],
      imgTemp: [],
      loadingClaims: false,
      maxFileSize: 2000000,
      fileSizeExceeded: false,
      prevImg: null,
      isInputFocused: false,
    }
  },
  computed: {
    inputModel: {
      get() {
        return this.isInputFocused ? this.fullUrl : this.shortUrl
      },
      set(value) {
        this.fullUrl = value
        this.shortUrl = value
      },
    },
  },
  methods: {
    show() {
      this.$refs['modal-force'].show()
    },
    closeModalForce() {
      this.urlError = ''
      this.fileList = []
      this.fullUrl = null
      this.shortUrl = null
      this.fileSizeExceeded = false
    },
    onInputFocus() {
      this.isInputFocused = true
    },
    validationUrl() {
      if (this.fullUrl !== '') {
        if (!urlPattern.test(this.inputModel)) {
          this.urlError = 'invalid'
        } else {
          if (this.inputModel.length > 40) {
            this.shortUrl = `${this.inputModel.slice(0, 40)}...`
          }
          this.urlError = ''
        }
      } else {
        this.urlError = ''
      }
      this.isInputFocused = false
    },
    async handleFileInput($event) {
      this.imgTemp = []
      const files = Array.from($event.target.files)
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        if (this.fileList.length < 3) {
          if (
            ['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)
          && !file.name.includes('jfif')
          && file.size < this.maxFileSize
          ) {
            this.fileSizeExceeded = false
            // eslint-disable-next-line no-await-in-loop
            await this.processFile(file)
          } else {
            this.fileSizeExceeded = true
          }
        }
      }
    },
    async processFile(file) {
      await this.uploadImg(file)
    },
    async uploadImg(file) {
      const formData = new FormData()
      formData.append('file', file)
      await this.$http_new.post('komship/api/v1/claims/upload-img/temp', formData).then(response => {
        const { data } = response.data
        this.imgTemp.push(data.id)
        this.fileList.push(data)
        this.nameFiles.push(data.url)
      }).catch(err => {
        this.loadingClaims = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failure',
            icon: 'AlertCircleIcon',
            text: err,
            variant: 'danger',
          },
        }, 2000)
      })
    },
    removeFile(index, file) {
      this.nameFiles.splice(index, 1)
      this.fileList.splice(index, 1)
    },
    openPreview(file) {
      this.prevImg = file.url
      this.$refs['modal-preview-img'].show()
    },
    hideModalForceDelivered() {
      this.closeModalForce()
      this.$refs['modal-force'].hide()
    },
    submit() {
      this.loadingClaims = true
      const params = {
        order_id: this.orderId,
        type: this.status === 'delivered' ? 'paksa_diterima' : 'paksa_rts',
      }

      if (this.fullUrl !== null) Object.assign(params, { video_url: this.fullUrl })
      if (this.imgTemp.length > 0) Object.assign(params, { images_id: this.fileList.map(file => file.id) })

      this.$http_new.post('komship/api/v1/claims', params).then(() => {
        this.loadingClaims = false
        this.hideModalForceDelivered()
        this.$refs['modal-success'].show()
      }).catch(err => {
        this.loadingClaims = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failure',
            icon: 'AlertCircleIcon',
            text: 'Gagal Submit Claim',
            variant: 'danger',
          },
        }, 2000)
      })
    },
    validationForce() {
      if (this.fullUrl !== null && this.urlError === 'invalid') {
        return true
      }
      if (this.fileList.length < 1 || this.fileSizeExceeded) {
        return true
      }
      return false
    },
  },
}
</script>

<style scoped>
    .wrapper-choose-file {
        height: 35px;
        border-radius: 8px 0px 0px 8px;
        border: 1px solid #E2E2E2;
        width: 88.6%;
    }

    .wrapper-preview-file {
      position: relative;
    }

    .img-preview {
      border-radius: 12px;
      cursor: pointer;
      width: 60px;
      height: 60px;
    }

    .close-img-preview {
      position: absolute;
      top: -15%;
      right: 5%;
      cursor: pointer;
    }

    .input-group > .form-control:not(:last-child) {
      color: #08A0F7;
    }

    .btn-submit-disabled {
      border-radius: 8px;
      border: 1px solid #E2E2E2!important;
      background: #E2E2E2!important;
      color: #C2C2C2!important;
      cursor: not-allowed;
    }
    .btn-submit {
        border-radius: 8px;
    }
</style>
